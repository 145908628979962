<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-newspaper">
          <crud
            :crudSettings="crudSettings"
            :headers="headers"
            :responseData="{data:items}"
            :detailData="detailData"
            :forceUpdateTable="forceUpdate"
            @onTableChange="updateTable"
          >
          </crud>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import Crud from '@/components/common/Crud'

export default {
  components:{
    Crud,
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Judul', value:'judul', sortable:false, class:'header-index-profile'},
        {text:'Tanggal Posting', value:'tanggal', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      items:[
        {'no':1, 'judul' :'Pengumuman', 'tanggal':'17 Febuari 2018 15:00:11'}
      ],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
      },
    }
  },

  watch:{},

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
  },

  computed:{},

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){},
  },
}
</script>

<style lang="css" scoped>
</style>
